<template>
  <ShadowCard :param="{ name: `Add New ${title}`, showButtons: false }">
    <div class="row justify-content-between mb-4 mt-4">
      <div class="col">
        <FormulateForm
          name="FormAddNew"
          v-model="formValues"
          :schema="postFields"
          @submit="handleSubmit"
          ref="form"
          class="custom-outer" />
      </div>
    </div>
    <div class="row justify-content-between">
      <BackButton />
      <div class="col-8 text-right">
        <ResetButton @reset-form="resetForm" />
        <SubmitButton
          :ShowSubmitButton="showSubmitButton"
          :ShowOverlay="showOverlay"
          @submit-form="handleSubmitBtnClick" />
      </div>
    </div>
  </ShadowCard>
</template>

<script>
import { postItem, getSecurityLastPrice } from "../../../services/api.js";
import ShadowCard from "@/presentationalComponents/ShadowCard.vue";
import BackButton from "@/components/buttons/backButton.vue";
import ResetButton from "@/components/buttons/resetButton.vue";
import SubmitButton from "@/components/buttons/submitButton.vue";

import moment from "moment";

export default {
  name: "FormAddNew",
  components: {
    ShadowCard,
    BackButton,
    ResetButton,
    SubmitButton,
  },

  data() {
    return {
      formValues: {},
      date: "",
      prices: [],
      securities: [],
      transfers: [],
      orders: [],
      balances: [],
      newItem: {},
      currencies: [],
      showOverlay: false,
      showSubmitButton: false,

      dataLoaded: false,
    };
  },

  created() {
    this.$store.dispatch("loadSecuritiesList");
    this.$store.dispatch("loadCurrenciesList");
    //Schemas for every form field
    this.schemaArrays();
  },
  watch: {
    dataIsLoaded: {
      handler: function () {
        const updateOptionsList = [
          { fieldName: "portfolio_id", options: this.portfolioList },
          { fieldName: "currency", options: this.currenciesList },
          { fieldName: "asset_id", options: this.securitiesList },
        ];

        this.updateFieldOptions(updateOptionsList);
      },
      immediate: true,
    },
  },
  computed: {
    dataIsLoaded() {
      return (
        this.portfolioList.length > 0 &&
        this.securitiesList.length > 0 &&
        this.currenciesList.length > 0
      );
    },
    currentDate() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    portfolioList() {
      return this.$store.state.portfolioList.map((portfolio) => ({
        value: portfolio.portfolio_id,
        label: portfolio.portfolio_id,
      }));
    },
    securitiesList() {
      return this.$store.state.securitiesList.map((security) => {
        let identifier = security.figi || security.isin || security.bbg_ticker;
        return {
          value: security.asset_id,
          label: security.name + " (" + security.bbg_ticker + ")",
          attrs: {
            identifier: identifier,
          },
        };
      });
    },
    currenciesList() {
      return this.$store.state.currenciesList.map((currency) => ({
        value: currency.currency_code,
        label: currency.currency_code + " - " + currency.description,
      }));
    },

    currencyFilter(option, search) {
      return option.currency_code.toLowerCase().includes(search.toLowerCase());
    },

    uri() {
      let segment_str = this.$route.fullPath; // return segment1/segment2/segment3/segment4
      let segment_array = segment_str.split("/");
      let last_segment = segment_array.pop();
      return segment_array[segment_array.length - 1];
    },
    title() {
      let segment_str = this.$route.fullPath; // return segment1/segment2/segment3/segment4
      let segment_array = segment_str.split("/");
      let last_segment = segment_array.pop();

      return this.makeSingular(
        this.capitalizeFirstLetter(
          last_segment == "addnew"
            ? segment_array[segment_array.length - 1]
            : last_segment
        )
      );
    },
    postFields() {
      return this[this.uri];
    },

    editableObjectt() {
      let PreEditableObject = this.postFields
        .map((item) => item.field)
        .map((element) => {
          return [element, ""];
        });
      this.newItem = Object.fromEntries(PreEditableObject);
      return Object.fromEntries(PreEditableObject);
    },

    route() {
      let segment_str = this.$route.fullPath;
      let returnPathArray = segment_str.split("/");
      let last_segment = returnPathArray.pop();
      return returnPathArray.join("/");
    },

    id_property() {
      return this.$store.state.table_field_id;
    },
  },
  methods: {
    schemaArrays() {
      this.transfers = [
        {
          type: "group",
          name: "transfers",
          "remove-position": "after",
          validation: "min:1,length",
          "add-label": "+ Add new row",
          repeatable: true,
          value: [{}],
          minimum: 1,
          children: [
            {
              type: "date",
              name: "date",
              value: this.currentDate,
              validation: "required",
              inputClass: ["input-date"],
            },
            {
              type: "select",
              name: "portfolio_id",
              placeholder: "Select a Portfolio",
              options: this.portfolioList,
              validation: "required",
              inputClass: ["select-portfolio"],
            },
            {
              type: "text",
              name: "description",
              placeholder: "Description",
              validation: "required",
            },
            {
              type: "currency",
              prefix: "$",
              name: "debit",
              placeholder: "Debit",
              validation: "required",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              prefix: "$",
              name: "credit",
              placeholder: "Credit",
              validation: "required",
              inputClass: ["input-amount"],
            },
            {
              type: "select",
              name: "currency",
              placeholder: "Currency",
              validation: "required",
              options: this.currenciesList,
              inputClass: ["select-currency"],
            },
          ],
        },
      ];
      this.orders = [
        {
          type: "group",
          name: "orders",
          "remove-position": "after",
          validation: "min:1,length",
          "add-label": "+ Add new row",
          repeatable: true,
          value: [{}],
          minimum: 1,
          children: [
            {
              type: "date",
              name: "date",
              value: this.currentDate,
              validation: "required",
              inputClass: ["input-date"],
            },
            {
              type: "select",
              name: "portfolio_id",
              placeholder: "Select a Portfolio",
              options: this.portfolioList,
              validation: "required",
              inputClass: ["select-portfolio"],
            },
            {
              type: "select",
              name: "asset_id",
              placeholder: "Select an Asset",
              options: this.securitiesList,
              "@change": this.handleSecurityChange,
              validation: "required",
              inputClass: ["select-asset"],
            },
            {
              type: "text",
              name: "description",
              placeholder: "Description",
              validation: "required",
            },

            {
              type: "currency",
              decimalLimit: 6,
              name: "principal",
              placeholder: "Principal",
              validation: "required",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              prefix: "$",
              decimalLimit: 6,
              name: "price",
              value: "",
              placeholder: "Price",
              validation: "required",
              inputClass: ["input-amount"],
            },
            {
              type: "select",
              name: "tif",
              placeholder: "TIF",
              options: ["DAY", "GTC", "IOC", "FOK", "MOO"],
              validation: "required",
            },
            {
              type: "select",
              name: "type",
              placeholder: "Type",
              options: ["MKT", "LMT", "STP"],
              validation: "required",
            },
            {
              type: "select",
              name: "side",
              placeholder: "Side",
              options: ["BUY", "SELL", "SELL SHORT"],
              validation: "required",
            },
            {
              type: "select",
              name: "status",
              placeholder: "Status",
              options: ["EXECUTED", "PENDING", "CANCELLED"],
              validation: "required",
            },
          ],
        },
      ];
      this.balances = [
        {
          type: "group",
          name: "balances",
          "remove-position": "after",
          validation: "min:1,length",
          "add-label": "+ Add new row",
          repeatable: true,
          value: [{}],
          minimum: 1,
          children: [
            {
              type: "date",
              name: "value_date",
              value: this.currentDate,
              validation: "required",
              inputClass: ["input-date"],
            },
            {
              type: "select",
              name: "portfolio_id",
              placeholder: "Select a Portfolio",
              options: this.portfolioList,
              validation: "required",
              inputClass: ["select-portfolio"],
            },
            {
              type: "text",
              name: "year",
              placeholder: "YYYY",
              value: moment().year() - 1,
              validation: "required|date:YYYY|min:2000,value",
              inputClass: ["input-year"],
            },
            {
              type: "currency",
              decimalLimit: 6,
              name: "amount",
              placeholder: "Amount",
              validation: "required",
              inputClass: ["input-amount"],
            },
            {
              type: "select",
              name: "currency",
              placeholder: "Currency",
              options: this.currenciesList,
              validation: "required",
              inputClass: ["select-currency"],
            },
          ],
        },
      ];
      this.securities = [
        {
          type: "group",
          name: "securities",
          "remove-position": "after",
          validation: "min:1,length",
          "add-label": "+ Add new row",
          repeatable: true,
          value: [{}],
          minimum: 1,
          children: [
            {
              type: "text",
              name: "name",
              placeholder: "Name",
              validation: "required",
            },
            {
              type: "text",
              name: "description",
              placeholder: "Description",
            },
            {
              type: "text",
              name: "bbg_ticker",
              placeholder: "BBG Ticker",
              validation: "required",
            },
            {
              type: "text",
              name: "ticker",
              placeholder: "Ticker",
              validation: "required",
            },
            {
              type: "number",
              name: "multiplier",
              placeholder: "Multiplier",
              validation: "required",
              inputClass: ["input-amount"],
            },
            {
              type: "select",
              name: "asset_class",
              placeholder: "Asset Class",
              validation: "required",
              options: [
                { label: "Comdty", value: "Comdty" },
                { label: "Corp", value: "Corp" },
                { label: "Curncy", value: "Curncy" },
                { label: "Equity", value: "Equity" },
                { label: "ETF", value: "ETF" },
                { label: "ETN", value: "ETN" },
                { label: "Future", value: "Future" },
                { label: "Fwd", value: "Fwd" },
                { label: "Govt", value: "Govt" },
                { label: "HedgeFnd", value: "HedgeFnd" },
                { label: "Index", value: "Index" },
                { label: "Loan", value: "Loan" },
                { label: "MonMkt", value: "MonMkt" },
                { label: "Mtge", value: "Mtge" },
                { label: "Muni", value: "Muni" },
                { label: "N/A", value: "N/A" },
                { label: "Option", value: "Option" },
                { label: "Pfd", value: "Pfd" },
                { label: "PreMet", value: "PreMet" },
                { label: "PrvEquity", value: "PrvEquity" },
                { label: "RealEst", value: "RealEst" },
                { label: "SP-Comdty", value: "SP-Comdty" },
                { label: "SP-Curncy", value: "SP-Curncy" },
                { label: "SP-Equity", value: "SP-Equity" },
                { label: "SP-FixInc", value: "SP-FixInc" },
                { label: "SP-Index", value: "SP-Index" },
                { label: "SP-RealEst", value: "SP-RealEst" },
                { label: "Swap", value: "Swap" },
                { label: "Warrant", value: "Warrant" },
              ],
              inputClass: ["select-asset"],
            },
            {
              type: "text",
              name: "isin",
              placeholder: "ISIN",
              validation: "required",
            },
            {
              type: "text",
              name: "cusip",
              placeholder: "CUSIP",
            },
            {
              type: "text",
              name: "sedol1",
              placeholder: "SEDOL",
            },
            {
              type: "select",
              name: "currency_id",
              placeholder: "Currency",
              validation: "required",
              options: this.currenciesList,
              inputClass: ["select-currency"],
            },
            {
              name: "is_otc",
              label: "OTC",
              type: "checkbox",
            },
            {
              name: "active",
              label: "Active",
              type: "checkbox",
            },
          ],
        },
      ];
      this.prices = [
        {
          type: "group",
          name: "prices",
          "remove-position": "after",
          validation: "min:1,length",
          "add-label": "+ Add new row",
          repeatable: true,
          value: [{}],
          minimum: 1,
          children: [
            {
              type: "date",
              name: "date",
              value: this.currentDate,
              validation: "required",
              inputClass: ["input-date"],
            },
            {
              type: "select",
              name: "asset_id",
              placeholder: "Select an Asset",
              options: this.securitiesList,
              "@change": this.handleSecurityChange,
              validation: "required",
              inputClass: ["select-asset"],
            },
            {
              type: "currency",
              prefix: "$",
              decimalLimit: 6,
              name: "last",
              placeholder: "Last",
              validation: "required",
              inputClass: ["input-amount"],
              model: "last",
            },
            {
              type: "currency",
              prefix: "$",
              decimalLimit: 6,
              name: "prev_close",
              placeholder: "Prev Close",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              prefix: "$",
              decimalLimit: 6,
              name: "open",
              placeholder: "Open",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              prefix: "$",
              decimalLimit: 6,
              name: "low",
              placeholder: "Low",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              prefix: "$",
              decimalLimit: 6,
              name: "high",
              placeholder: "High",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              name: "volume",
              placeholder: "Volume",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              prefix: "$",
              name: "int_acc",
              placeholder: "Int. Accrued",
              inputClass: ["input-amount"],
            },
            {
              type: "currency",
              name: "ytm",
              placeholder: "YTM",
              inputClass: ["input-amount"],
            },
          ],
        },
      ];
    },

    updateFieldOptions(updateOptionsList) {
      // Itero sobre la lista de opciones para actualizar
      // cada objeto con el nombre especifico declarado en la lista (watch)
      console.log(this.postFields);
      updateOptionsList.forEach((updateOption) => {
        const { fieldName, options } = updateOption;

        this.postFields.forEach((field) => {
          // Busco el objeto con el nombre especificado
          // hago una comparación exacta ya que para la list de currency
          // el fieldName puede ser currency o currency_id
          const arrayChildren = field.children.filter(
            (object) =>
              object.name === fieldName || object.name === `${fieldName}_id`
          );
          // Si se encuentra dentro del array de objetos con el nombre especificado, actualizo sus opciones
          if (arrayChildren.length > 0) {
            arrayChildren.forEach((targetObject) => {
              targetObject.options = options;
            });
          }
        });
      });
    },

    handleSecurityChange(event) {
      //this.formValues.price = "";
      let identifierAttr =
        event.target[event.target.selectedIndex].getAttribute("identifier");

      if (!identifierAttr) {
        return;
      }

      getSecurityLastPrice(identifierAttr).then((response) => {
        const securityLastPrice = response.data;

        for (const date in securityLastPrice.data) {
          for (const identifier in securityLastPrice.data[date]) {
            const closePriceObj = securityLastPrice.data[date][identifier];
            this.formValues.price = closePriceObj[0].closePrice;
          }
        }
      });
    },

    handleSubmitBtnClick() {
      this.$formulate.submit("FormAddNew");
    },

    handleSubmit() {
      this.postCall(this.uri, this.formValues);
    },

    resetForm() {
      this.$formulate.reset("FormAddNew");
      //this.formValues.date = this.currentDate;
      this.$refs.form.$el.querySelectorAll("input, select").forEach((input) => {
        input.disabled = false;
      });
      this.showSubmitButton = false;
    },

    filterNumber(value) {
      // Remove sign, comma, and dot from the value
      const filteredValue = value.replace(/[^\d.-]/g, "");
      return filteredValue;
    },

    postCall(uri, formValues) {
      this.showOverlay = true;
      // Filtro los children para que sólo incluyan campos de tipo "currency"
      // mapeo los campos filtrados y extraigo el nombre de cada campo de tipo "currency"
      const currencyFieldsByName = this.postFields[0].children
        .filter((field) => field.type === "currency")
        .map((field) => field.name);

      const filteredFormValuesToPost = (formValues) => {
        const filteredFormValues = {};

        // Primer loop, recorro cada entrada del objeto formValues
        for (const [rowObject, rowObjectValue] of Object.entries(formValues)) {
          if (Array.isArray(rowObjectValue)) {
            // Segundo loop, recorro cada elemento del array
            filteredFormValues[rowObject] = rowObjectValue.map((row) => {
              const filteredRow = {};
              // Tercer loop, recorro cada key-value del objeto row para filtrar solo los campos de tipo "currency"
              for (const [fieldName, fieldValue] of Object.entries(row)) {
                // Si la fieldName está en currencyFieldsByName, aplico filterNumber, sino, queda el valor original
                filteredRow[fieldName] = currencyFieldsByName.includes(
                  fieldName
                )
                  ? this.filterNumber(fieldValue)
                  : fieldValue;
              }
              return filteredRow;
            });
          }
        }
        return filteredFormValues;
      };

      const filteredFormValues = filteredFormValuesToPost(formValues);

      postItem(uri, filteredFormValues).then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.makeToast(
            "success",
            response.status,
            this.title + " created successfully"
          );

          this.$refs.form.$el
            .querySelectorAll("input, select")
            .forEach((input) => {
              input.disabled = true;
            });

          this.showSubmitButton = true;
          this.showOverlay = false;
        }
      });
    },

    makeToast(variant = null, status, text) {
      this.$bvToast.toast([text], {
        title: `Code: ${status || "default"}`,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    capitalizeTransfersKeys(obj) {
      let entries = Object.entries(obj);
      let capsEntries = entries.map((entry) => [
        entry[0][0].toUpperCase() + entry[0].slice(1),
        entry[1],
      ]);
      let capsObject = Object.fromEntries(capsEntries);
      capsObject.portfolio_id = capsObject.Portfolio_id;
      return capsObject;
    },
    makeSingular(string) {
      let singular;
      string.slice(-3) == "ies"
        ? (singular = string.slice(0, -3) + "y")
        : (singular = string.slice(0, -1));
      return singular;
    },
  },
};
</script>

<style>
.wrapper-checkbox {
  width: 100%;
  display: flex;
  align-items: stretch;
  padding-top: 0.75rem !important;
}
.input-checkbox {
  width: 1rem;
  height: 1rem;
}

.input-date {
  min-width: 135px;
}
.input-year {
  min-width: 95px;
}
.input-amount {
  min-width: 120px;
  text-align: right;
}
.input-amount::placeholder {
  text-align: left;
}
.select-portfolio {
  min-width: 190px;
}
.select-asset {
  min-width: 190px;
}
.select-currency {
  min-width: 190px;
}

@media screen and (min-width: 576px) {
  .input-date {
    width: 150px;
  }
  .select-portfolio {
    width: 190px;
  }
  .select-asset {
    width: 190px;
  }
  .input-amount {
    width: 120px;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(0.95em + 0.1875rem) center !important;
}
.field-border-bottom-only {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
}

.formulate-input-group-repeatable-remove {
  position: relative;
  display: block;
  top: 50%; /* Cambiar bottom por top */
  transform: translateY(50%); /* Usar transform para centrar verticalmente */
  width: 1.3em;
  height: 1.3em;
  background-color: #cecece;
  left: 0.85em;
  border-radius: 1.3em;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;
  text-indent: -1000px;
}

.formulate-input-group-repeatable-remove[data-disabled] {
  display: none;
  pointer-events: none;
}

.formulate-input-group-repeatable-remove:after,
.formulate-input-group-repeatable-remove:before {
  content: "";
  position: absolute;
  bottom: calc(50% - 0.1em);
  left: 0.325em;
  display: block;
  width: 0.65em;
  height: 0.2em;
  background-color: #fff;
  transform-origin: center center;
  transition: transform 0.25s;
}

@media (pointer: fine) {
  .formulate-input-group-repeatable-remove:hover {
    background-color: #dc2c2c;
  }

  .formulate-input-group-repeatable-remove:hover:after,
  .formulate-input-group-repeatable-remove:hover:before {
    height: 0.2em;
    width: 0.75em;
    left: 0.25em;
    top: calc(50% - 0.075em);
  }

  .formulate-input-group-repeatable-remove:hover:after {
    transform: rotate(45deg);
  }

  .formulate-input-group-repeatable-remove:hover:before {
    transform: rotate(-45deg);
  }
}

/* Aqui se generan los estilos para el boton de agregar una nueva fila  */

.formulate-input-element--button {
  max-width: 20em;
  margin-top: 0.5rem;
}

.formulate-input-element button {
  padding: 0.35em;
  width: 100%;
  margin: 0;
  color: #000000;
  background-color: transparent;
  min-width: 0;
  width: auto;
  cursor: pointer;
  align-items: center;
  border-width: 0px;
}

.formulate-input-element button:hover {
  color: white;
  border-width: 0px;
  background-color: #6c757d;
}

.custom-outer .formulate-input {
  margin-right: 1rem;
}
</style>
